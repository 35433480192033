import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/App.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ErrorPage from "./ErrorPage";
import PurchaseOrder from "./routes/PurchaseOrder";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "purchase-order",
                element: <PurchaseOrder />,
                loader: async () => {
                    const res = await fetch(`${process.env.REACT_APP_BASE_URL}/poSubmission/userInfo`)
                    if (res.status === 404) {
                        throw new Response("Not Found", { status: 404 })
                    }
                    const userInfo = await res.json()
                    if (!userInfo.customer) {
                        throw new Response("User does not have authorization to submit purchase orders.", { status: 403 })
                    }
                    return userInfo
                }
            }
        ]
    }
], {basename: "/app"});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
