import { useRouteError } from "react-router-dom";
import {Alert, Container} from "react-bootstrap";
import Navigation from "./components/navigation/Navigation"
import React from "react"

export default function ErrorPage() {
    const error: any = useRouteError();
    console.error(error);

    return (
        <div>
            <header>
                <Navigation />
            </header>
            <Container className="text-center p-5">
                <Alert variant={"danger"} className={"p-5"}>
                    <h1>{error.status}</h1>
                    <p>{error.statusText || error.data}</p>
                </Alert>
            </Container>
        </div>
    )
}
