import {Fragment, useEffect, useState} from "react"
import ProductConfiguration from "./ProductConfiguration"
import PurchaseOrderFormLine from "../../type/PurchaseOrderFormLine"
import LineItemList from "./LineItemList"
import {Alert, Button, Card, Col, Form, Modal, Placeholder, Row, Stack} from "react-bootstrap"
import {Formik} from "formik"
import {boolean, date, object, string} from "yup"
import PurchaseOrderFormValues from "../../type/PurchaseOrderForm"
import PurchaseOrderShippingAddress from "../../type/PurchaseOrderShippingAddress"
import ShippingMethod from "../../type/ShippingMethod"
import SubmissionForm from "./SubmissionForm"
import PurchaseOrderUserInfo from "../../type/PurchaseOrderUserInfo"

export interface PurchaseOrderFormProps {
    userInfo: PurchaseOrderUserInfo
}
export default function PurchaseOrderForm(props: PurchaseOrderFormProps) {

    const schema = object({
        orderNumber: string().required(),
        proofRequired: boolean().required(),
        proofEmail: string().nullable(),
        shipDate: date().required().default(() => new Date()),
        inHandsDate: date().required().default(() => new Date()),
        // shipDate: date().required(),
        // inHandsDate: date().required(),
        attentionTo: string().required(),
        companyName: string().required(),
        addressLine1: string().required(),
        addressLine2: string().nullable(),
        addressLine3: string().nullable(),
        city: string().required(),
        state: string().required(),
        postalCode: string().required(),
        shippingAccountNumber: string().nullable(),
        shippingMethod: string().required(),
    })

    const initialValues: PurchaseOrderFormValues = {
        orderNumber: "",
        proofRequired: "false",
        proofEmail: props.userInfo.customer?.email ?? "",
        lines: [],
        shipDate: "",
        inHandsDate: "",
        attentionTo: "",
        companyName: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        city: "",
        state: "",
        postalCode: "",
        shippingAccountNumber: "",
        shippingMethod: ""
    }

    const [addressError, setAddressError] = useState<string|null>(null)
    const [isLoadingAddresses, setIsLoadingAddresses] = useState<boolean>(false)
    const [isLoadingShippingMethods, setIsLoadingShippingMethods] = useState<boolean>(false)
    const [lineItems, setLineItems] = useState<PurchaseOrderFormLine[]>([])
    const [selectedAddress, setSelectedAddress] = useState<string>("")
    const [shippingAddresses, setShippingAddresses] = useState<PurchaseOrderShippingAddress[]>([])
    const [shippingMethodError, setShippingMethodError] = useState<string|null>(null)
    const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>([])
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [complete, setComplete] = useState<boolean>(false)

    /**
     * Fetch address data
     */
    useEffect(() => {
        setIsLoadingAddresses(true)
        setAddressError(null)
        fetch(`${process.env.REACT_APP_BASE_URL}/poSubmission/addresses`)
            .then(data => data.json())
            .then(data => setShippingAddresses(data))
            .catch(error => {
                setAddressError("Unable to load address data.")
                console.error(error)
            })
            .finally(() => setIsLoadingAddresses(false))
    }, [])

    /**
     * Fetch shipping method data
     */
    useEffect(() => {
        setIsLoadingShippingMethods(true)
        setShippingMethodError(null)
        fetch(`${process.env.REACT_APP_BASE_URL}/poSubmission/shippingMethods`)
            .then(data => data.json())
            .then(data => setShippingMethods(data))
            .catch(error => {
                setShippingMethodError("Unable to load shipping method data.")
                console.error(error)
            })
            .finally(() => setIsLoadingShippingMethods(false))
    }, [])

    function handleAddLine(lineItem: PurchaseOrderFormLine) {
        setLineItems(prevState => [...prevState, lineItem])
    }

    function handleModalClose() {
        if (complete) {
            window.location.reload()
        } else {
            setShowConfirmation(false)
        }
    }

    function handleRemoveLine(index: number) {
        const newLineItems = [...lineItems].filter((line, i) => i !== index)
        setLineItems(newLineItems)
    }

    function handleSelectedAddressChange(key: string, values: PurchaseOrderFormValues, setValues: Function) {
        setSelectedAddress(key)
        const shippingAddress = shippingAddresses.find(address => address.id.toString() === key)
        if (shippingAddress) {
            const newValues = {
                shippingAccountNumber: shippingAddress.accountNumber ?? "",
                companyName: shippingAddress.locationName ?? "",
                addressLine1: shippingAddress.addressLine1 ?? "",
                addressLine2: shippingAddress.addressLine2 ?? "",
                addressLine3: shippingAddress.addressLine3 ?? "",
                city: shippingAddress.city ?? "",
                state: shippingAddress.state ?? "",
                postalCode: shippingAddress.postalCode ?? "",
            }
            setValues({...values, ...newValues})
        } else {
            const newValues = {
                shippingAccountNumber: "",
                companyName: "",
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                city: "",
                state: "",
                postalCode: "",
            }
            setValues({...values, ...newValues})
        }
    }

    function handleSubmit(values: PurchaseOrderFormValues) {
        setShowConfirmation(true)
    }

    return (
        <Stack direction={"vertical"} gap={3}>
            <h2>Products</h2>
            <ProductConfiguration onSubmit={handleAddLine} />
            {lineItems.length === 0 && <Alert className={"bg-body-secondary text-muted"} variant={"none"}><i className={"bi bi-info-circle me-1"} /> At least one product is required to submit a purchase order.</Alert> }
            {lineItems.length > 0 &&
                <Fragment>
                    <h2>Line Items</h2>
                    <LineItemList lineItems={lineItems} onRemove={handleRemoveLine} />
                </Fragment>
            }

            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                {({ handleBlur, handleSubmit, handleChange, setValues, values, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row className={"g-3"}>
                            <Col xs={12}>
                                <h2>Purchase Order Details</h2>
                            </Col>

                            <Form.Group as={Col} xs={12}>
                                <Form.Label><strong>Order Number *</strong></Form.Label>
                                <Form.Control isInvalid={!!errors.orderNumber}
                                              isValid={touched.orderNumber && !errors.orderNumber}
                                              name="orderNumber"
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              type={"text"}
                                              value={values.orderNumber} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.orderNumber}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} lg={6}>
                                <Form.Label><strong>Proof Required *</strong></Form.Label>
                                <Form.Select isInvalid={!!errors.proofRequired}
                                              isValid={touched.proofRequired && !errors.proofRequired}
                                              name="proofRequired"
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              value={values.proofRequired}>
                                    <option value={"true"}>Yes</option>
                                    <option value={"false"}>No</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {errors.proofRequired}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} lg={6}>
                                <Form.Label><strong>Proof Email *</strong></Form.Label>
                                <Form.Control disabled={values.proofRequired === "false"}
                                              isInvalid={!!errors.proofEmail}
                                              isValid={touched.proofEmail && !errors.proofEmail}
                                              name="proofEmail"
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              type={"text"}
                                              value={values.proofEmail} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.orderNumber}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Col xs={12}>
                                <h2>Shipping Details</h2>
                            </Col>

                            <Form.Group as={Col} xs={12} lg={6}>
                                <Form.Label><strong>Ship Date *</strong></Form.Label>
                                <Form.Control isInvalid={!!errors.shipDate}
                                              isValid={touched.shipDate && !errors.shipDate}
                                              name="shipDate"
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              type={"date"}
                                              value={values.shipDate} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.shipDate}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} lg={6}>
                                <Form.Label><strong>In Hands Date</strong></Form.Label>
                                <Form.Control isInvalid={!!errors.inHandsDate}
                                              isValid={touched.inHandsDate && !errors.inHandsDate}
                                              name="inHandsDate"
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              type={"date"}
                                              value={values.inHandsDate} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.inHandsDate}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} lg={6}>
                                <Form.Label><strong>Shipping Account Number</strong></Form.Label>
                                <Form.Control isInvalid={touched.shippingAccountNumber && !!errors.shippingAccountNumber}
                                              isValid={touched.shippingAccountNumber && !errors.shippingAccountNumber}
                                              name="shippingAccountNumber"
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              type={"text"}
                                              value={values.shippingAccountNumber} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.shippingAccountNumber}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} xs={12} lg={6}>
                                {isLoadingShippingMethods &&
                                    <Placeholder animation={"glow"}>
                                        <Placeholder as={Form.Label} xs={6} />
                                        <Placeholder as={Form.Control} xs={12} />
                                    </Placeholder>
                                }
                                {!isLoadingShippingMethods &&
                                    <Fragment>
                                        <Form.Label><strong>Shipping Method *</strong></Form.Label>
                                        <Form.Select isInvalid={touched.shippingMethod && !!errors.shippingMethod}
                                                      isValid={touched.shippingMethod && !errors.shippingMethod}
                                                      name="shippingMethod"
                                                      onBlur={handleBlur}
                                                      onChange={handleChange}
                                                      value={values.shippingMethod}>
                                            <option value={""}>Select a shipping method</option>
                                            {shippingMethods.map(shippingMethod =>
                                                <option key={shippingMethod.code} value={shippingMethod.code}>{shippingMethod.name}</option>
                                            )}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.shippingMethod}
                                        </Form.Control.Feedback>
                                    </Fragment>
                                }
                                {!isLoadingShippingMethods && shippingMethodError &&
                                    <Alert className={"mt-3"} variant={"danger"}>
                                        <i className={"bi bi-exclamation-triangle me-2"} />
                                        {shippingMethodError}
                                    </Alert>
                                }
                            </Form.Group>

                            <Col xs={12}>
                                <h2>Shipping Address</h2>
                            </Col>

                            <Form.Group as={Col} xs={12}>
                                <Form.Label><strong>Attention To *</strong></Form.Label>
                                <Form.Control isInvalid={touched.attentionTo && !!errors.attentionTo}
                                              isValid={touched.attentionTo && !errors.attentionTo}
                                              name="attentionTo"
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              type={"text"}
                                              value={values.attentionTo} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.attentionTo}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Col xs={12}>
                                {isLoadingAddresses &&
                                    <Placeholder animation={"glow"}>
                                        <Placeholder className={"rounded"} size={"lg"} xs={12} />
                                    </Placeholder>
                                }
                                {!isLoadingAddresses && addressError &&
                                    <Alert variant={"danger"}>
                                        <i className={"bi bi-exclamation-triangle me-2"} />
                                        {addressError}
                                    </Alert>
                                }
                                {!isLoadingAddresses &&
                                    <Card className={"bg-body-secondary"}>
                                        <Card.Body>
                                            <Form.Label><strong>Address</strong></Form.Label>
                                            <Form.Select onChange={(event) => handleSelectedAddressChange(event.target.value, values, setValues)} value={selectedAddress}>
                                                <option value={""}>Manual Address Entry</option>
                                                {shippingAddresses.map(address =>
                                                    <option key={address.id} value={address.id.toString()}>{address.locationName}</option>
                                                )}
                                            </Form.Select>
                                            <Form.Text>Select an address, or enter details manually.</Form.Text>

                                            {selectedAddress &&
                                                <div className={"mt-3"}>
                                                    {values.companyName}<br />
                                                    {values.addressLine1}<br />
                                                    {values.addressLine2.length > 0 &&
                                                        <Fragment>values.addressLine2<br /></Fragment>
                                                    }
                                                    {values.addressLine2.length > 0 &&
                                                        <Fragment>values.addressLine2<br /></Fragment>
                                                    }
                                                    {values.city}, {values.state} {values.postalCode}
                                                </div>
                                            }
                                        </Card.Body>
                                    </Card>
                                }
                            </Col>

                            {selectedAddress.length === 0 &&
                                <Fragment>
                                    <Form.Group as={Col} xs={12} lg={6}>
                                        <Form.Label><strong>Company Name *</strong></Form.Label>
                                        <Form.Control isInvalid={touched.companyName && !!errors.companyName}
                                                      isValid={touched.companyName && !errors.companyName}
                                                      name="companyName"
                                                      onBlur={handleBlur}
                                                      onChange={handleChange}
                                                      type={"text"}
                                                      value={values.companyName} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.companyName}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} xs={12} lg={6}>
                                        <Form.Label><strong>Address Line 1 *</strong></Form.Label>
                                        <Form.Control isInvalid={touched.addressLine1 && !!errors.addressLine1}
                                                      isValid={touched.addressLine1 && !errors.addressLine1}
                                                      name="addressLine1"
                                                      onBlur={handleBlur}
                                                      onChange={handleChange}
                                                      placeholder={"Street Address"}
                                                      type={"text"}
                                                      value={values.addressLine1} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.addressLine1}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} xs={12} lg={6}>
                                        <Form.Label><strong>Address Line 2</strong></Form.Label>
                                        <Form.Control isInvalid={touched.addressLine2 && !!errors.addressLine2}
                                                      isValid={touched.addressLine2 && !errors.addressLine2}
                                                      name="addressLine2"
                                                      onBlur={handleBlur}
                                                      onChange={handleChange}
                                                      placeholder={"Apt, Suite, Building (Optional)"}
                                                      type={"text"}
                                                      value={values.addressLine2} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.addressLine2}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} xs={12} lg={6}>
                                        <Form.Label><strong>Address Line 3</strong></Form.Label>
                                        <Form.Control isInvalid={touched.addressLine3 && !!errors.addressLine3}
                                                      isValid={touched.addressLine3 && !errors.addressLine3}
                                                      name="addressLine3"
                                                      onBlur={handleBlur}
                                                      onChange={handleChange}
                                                      type={"text"}
                                                      value={values.addressLine3} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.addressLine3}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} xs={12} lg={6}>
                                        <Form.Label><strong>City *</strong></Form.Label>
                                        <Form.Control isInvalid={touched.city && !!errors.city}
                                                      isValid={touched.city && !errors.city}
                                                      name="city"
                                                      onBlur={handleBlur}
                                                      onChange={handleChange}
                                                      type={"text"}
                                                      value={values.city} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.city}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} xs={12} lg={6}>
                                        <Form.Label><strong>State *</strong></Form.Label>
                                        <Form.Control isInvalid={touched.state && !!errors.state}
                                                      isValid={touched.state && !errors.state}
                                                      name="state"
                                                      onBlur={handleBlur}
                                                      onChange={handleChange}
                                                      type={"text"}
                                                      value={values.state} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.state}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} xs={12} lg={6}>
                                        <Form.Label><strong>Zip / Postal Code *</strong></Form.Label>
                                        <Form.Control isInvalid={touched.postalCode && !!errors.postalCode}
                                                      isValid={touched.postalCode && !errors.postalCode}
                                                      name="postalCode"
                                                      onBlur={handleBlur}
                                                      onChange={handleChange}
                                                      type={"text"}
                                                      value={values.postalCode} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.postalCode}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Fragment>
                            }

                            <Col xs={12}>
                                <Button disabled={lineItems.length < 1} type={"submit"}>Continue</Button>
                            </Col>
                        </Row>
                        <Modal show={showConfirmation} onHide={handleModalClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Purchase Order Submission</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <SubmissionForm values={values} lineItems={lineItems} onComplete={() => setComplete(true)} onClose={handleModalClose} />
                            </Modal.Body>
                        </Modal>
                    </Form>
                )}
            </Formik>
        </Stack>
    )
}
