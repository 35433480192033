import PurchaseOrderProductLocation from "../../type/PurchaseOrderProductLocation"
import {Card, Form, Spinner, Stack} from "react-bootstrap"
import {Fragment, useState} from "react"
import PurchaseOrderFormLocation from "../../type/PurchaseOrderFormLocation"
import PurchaseOrderDecoration from "../../type/PurchaseOrderDecoration"

export interface LocationListProps {
    locations: PurchaseOrderProductLocation[]
    onChange: (locations: {[index: string]: PurchaseOrderFormLocation}) => void
    selectedLocations: {[index: string]: PurchaseOrderFormLocation}
}

export default function LocationList(props: LocationListProps) {

    const [uploadingArtwork, setUploadingArtwork] = useState<boolean>(false)

    function handleLocationChange(location: PurchaseOrderProductLocation, selected: boolean) {
        let newState = {...props.selectedLocations}
        if (selected) {
            newState[location.locationId] = {
                location: location,
                decorations: {}
            }
        } else {
            delete newState[location.locationId]
        }
        props.onChange(newState)
    }

    function handleDecorationChange(location: PurchaseOrderProductLocation, decoration: PurchaseOrderDecoration, selected: boolean) {
        let newState = {...props.selectedLocations}
        if (selected) {
            newState[location.locationId].decorations[decoration.decorationId] = {
                decoration: decoration,
                filename: "",
                path: "",
                units: Array.from({ length: decoration.unitsMax }, (v, i) => "")
            }
        } else {
            delete newState[location.locationId].decorations[decoration.decorationId]
        }
        props.onChange(newState)
    }

    function handleFileChange(location: PurchaseOrderProductLocation, decoration: PurchaseOrderDecoration, element: any) {
        setUploadingArtwork(true)
        const files = element.files[0]
        const data = new FormData()
        data.append('artwork', files)
        fetch(`${process.env.REACT_APP_BASE_URL}/poSubmission/uploadArtwork`, {
            method: 'POST',
            body: data
        })
            .then(data => data.json())
            .then(data => {
                let newState = {...props.selectedLocations}
                newState[location.locationId].decorations[decoration.decorationId].filename = data.filename
                newState[location.locationId].decorations[decoration.decorationId].path = data.path
                props.onChange(newState)
            })
            .catch(error => {
                console.error(error)
            })
            .finally(() => setUploadingArtwork(false))
    }

    function handleUnitChange(location: PurchaseOrderProductLocation, decoration: PurchaseOrderDecoration, unit: string, index: number) {
        let newState = {...props.selectedLocations}
        newState[location.locationId].decorations[decoration.decorationId].units[index] = unit
        props.onChange(newState)
    }

    return (
        <div>
            {props.locations.map(location =>
                <Card key={location.locationId} className={"mb-3"}>
                    <Card.Body>
                        <Form.Group>
                            <Form.Check className={"fw-bold"} id={`location-${location.locationId}`} label={location.name} onChange={(event) => handleLocationChange(location, event.target.checked)} />
                        </Form.Group>
                        {props.selectedLocations[location.locationId] &&
                            <Fragment>
                                <div className={"h6 p-2 text-muted"}>Decoration Options</div>
                                {location.decorations.map((decoration, index) =>
                                    <Stack className={props.selectedLocations[location.locationId].decorations[decoration.decorationId] !== undefined ? "bg-body-secondary p-2 rounded" : "p-2"} gap={2} key={index}>
                                        <Form.Check className={"fw-bold"}
                                                    disabled={Object.keys(props.selectedLocations[location.locationId].decorations).length >= location.maxDecoration && !props.selectedLocations[location.locationId].decorations[decoration.decorationId]}
                                                    id={`location-${location.locationId}-decoration${decoration.decorationId}`}
                                                    label={decoration.name}
                                                    onChange={(event) => handleDecorationChange(location, decoration, event.target.checked)}
                                        />
                                        {props.selectedLocations[location.locationId].decorations[decoration.decorationId] !== undefined &&
                                            <Fragment>
                                                <div>Imprint Area: {decoration.width} x {decoration.height} {decoration.decorationUnitOfMeasure} <span className={"text-muted"}>(W x H)</span></div>
                                                <div>
                                                    <Form.Label><strong>Artwork</strong></Form.Label>
                                                    <Form.Control disabled={uploadingArtwork} name={"file"} onChange={(event) => handleFileChange(location, decoration, event.target)} type={"file"} />
                                                    {uploadingArtwork &&
                                                        <span><Spinner className={"me-2"} size={"sm"} /> Uploading Artwork...</span>
                                                    }
                                                </div>
                                            </Fragment>
                                        }
                                        {props.selectedLocations[location.locationId].decorations[decoration.decorationId]?.decoration.unitsMax > 0 &&
                                            <div>
                                                <Form.Label>
                                                    <strong>
                                                        {props.selectedLocations[location.locationId].decorations[decoration.decorationId]?.decoration.unitsIncludedUnitOfMeasure}
                                                    </strong>
                                                </Form.Label>
                                                <Stack direction={"vertical"} gap={2}>
                                                    {props.selectedLocations[location.locationId].decorations[decoration.decorationId]?.units.map((unit, index) =>
                                                        <div key={index}>
                                                            <Form.Control onChange={(event) => handleUnitChange(location, decoration, event.target.value, index)} value={unit} />
                                                            {index + 1 > props.selectedLocations[location.locationId].decorations[decoration.decorationId]?.decoration.unitsIncluded &&
                                                                <Form.Text>Extra charge may apply.</Form.Text>
                                                            }
                                                        </div>
                                                    )}
                                                </Stack>
                                            </div>
                                        }
                                    </Stack>
                                )}
                            </Fragment>
                        }
                    </Card.Body>
                </Card>
            )}
        </div>
    )
}
