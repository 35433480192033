import {Container} from "react-bootstrap"
import PurchaseOrderForm from "../components/purchase-order/PurchaseOrderForm"
import {useLoaderData} from "react-router-dom"
import PurchaseOrderUserInfo from "../type/PurchaseOrderUserInfo"

function PurchaseOrder() {

    const userInfo = useLoaderData() as PurchaseOrderUserInfo

    return (
        <Container className={"pb-5"}>
            <h1>Purchase Order</h1>
            <PurchaseOrderForm userInfo={userInfo} />
        </Container>
    )
}

export default PurchaseOrder
