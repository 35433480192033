import {Col, Container, Form, Nav, Navbar, NavDropdown, Placeholder, Row, Stack} from "react-bootstrap"
import React, {Fragment, useEffect, useState} from "react"
import NavigationType from "../../type/Navigation"
import NavigationLevel from "../../type/NavigationLevel"

function Navigation() {

    const [navigation, setData] = useState<NavigationType|null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [selectedNavigationLevel, setSelectedNavigationLevel] = useState<NavigationLevel|null>(null)
    const [showSearch, setShowSearch] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/site/navigation`)
            .then(response => response.json())
            .then(data => {
                setData(data)
            })
            .catch(() => console.error("Cannot load navigation data."))
            .finally(() => setIsLoading(false))
    }, [])

    async function handleSubmit(searchTerm: string) {
        const uri = `${process.env.REACT_APP_BASE_URL}/search/redirect?query={query}`.replace("{query}", encodeURIComponent(searchTerm))
        // return fetch(encodeURI(uri))
        //     .then(response => response.json())
        //     .then(data => data?.searchRedirect)
        //     .catch(() => {
        //         console.error("Cannot communicate with server for search redirects.")
        //     })
        window.location.href = uri || `/app/search?q=${searchTerm}`
    }

    return (
        <Fragment>
            <Navbar bg={"primary"} collapseOnSelect expand="lg" className={"position-relative"} variant={"dark"}>
                <Container className={"px-3 align-items-start"} fluid>
                    <Navbar.Brand href="/">
                        <img
                            alt="Hit Promotional Products logo"
                            src="https://www.hitpromo.net/images/brand/stacked-logo/logo-stacked-white.png"
                            height="80"
                            className="d-inline-block align-top"
                        />
                    </Navbar.Brand>
                    <Stack direction={"vertical"}>
                        <Stack className={"justify-content-end"} direction={"horizontal"} gap={3}>
                            <Stack className={"justify-content-end"} direction={"horizontal"} gap={3}>
                                <Stack className={"justify-content-end"} direction={"horizontal"}>
                                    <i className={"bi bi-search fs-5 text-white"} onClick={() => setShowSearch(!showSearch)} style={{cursor: "pointer"}} />
                                    <div style={{width: showSearch ? "100%" : "0%", marginLeft: showSearch ? "1rem" : "0rem", minWidth: showSearch ? "20rem" : "0rem", opacity: showSearch ? 1 : 0, transition: "all 0.5s"}}>

                                        <Form.Control
                                            onSubmit={() => handleSubmit("Test")}
                                        />
                                    </div>
                                </Stack>
                                <Nav.Link className={"text-white"} href={"/cart"}><i className="bi bi-cart3 fs-5" /><span className={"d-none d-lg-inline"}> Cart</span></Nav.Link>
                                {navigation?.authenticated === false &&
                                    <Nav.Link className={"text-white"} href={"/login"}><i className="bi bi-person-circle fs-5" /><span className={"d-none d-lg-inline"}> Login</span></Nav.Link>
                                }
                                {navigation?.authenticated &&
                                    <NavDropdown className={"text-white"}
                                                 title={<span><i className="bi bi-person-circle fs-5" /><span className={"d-none d-md-inline"}> {navigation.username}</span></span>}
                                                 id="user-dropdown"
                                    >
                                        <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
                                    </NavDropdown>
                                }
                            </Stack>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        </Stack>
                        <Navbar.Collapse className={"flex-column"} id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                {isLoading &&
                                    <Stack direction={"horizontal"} gap={3}>
                                        {Array.from({ length: 5 }, (el, index) =>
                                            <Placeholder animation={"wave"} key={index}>
                                                <Placeholder as={Nav.Link} xs={12} />
                                            </Placeholder>
                                        )}
                                    </Stack>
                                }
                                {navigation?.navigationLevels?.map((navigationLevel, index) =>
                                    <Fragment key={index}>
                                        {navigationLevel.path !== null && navigationLevel.navigationGroups.length === 0 &&
                                            <Nav.Link className={"d-lg-none text-white"}
                                                      href={navigationLevel.path ? `${process.env.REACT_APP_BASE_URL}/${navigationLevel.path}` : ""}
                                                      onMouseOver={() => setSelectedNavigationLevel(navigationLevel)}
                                            >
                                                {navigationLevel.label}
                                            </Nav.Link>
                                        }
                                        {navigationLevel.navigationGroups.length > 0 &&
                                            <NavDropdown className={"d-lg-none"}
                                                         title={<span className={"text-white"}>{navigationLevel.label}</span>}
                                                         id="user-dropdown"
                                            >
                                                {navigationLevel.navigationGroups.map((navigationGroup, index) =>
                                                    <div className={"mb-2"} key={index}>
                                                        <NavDropdown.ItemText><strong className={"text-primary"}>{navigationGroup.label}</strong></NavDropdown.ItemText>
                                                        {navigationGroup.navigationItems.map((navigationItem, index) =>
                                                            <NavDropdown.Item href={navigationItem.path} key={index}>{navigationItem.label}</NavDropdown.Item>
                                                        )}
                                                    </div>
                                                )}
                                            </NavDropdown>
                                        }
                                        <Nav.Link className={"d-none d-lg-block text-white"}
                                                  href={navigationLevel.path ? `${process.env.REACT_APP_BASE_URL}/${navigationLevel.path}` : ""}
                                                  onMouseOver={() => setSelectedNavigationLevel(navigationLevel)}
                                        >
                                            {navigationLevel.label}
                                        </Nav.Link>
                                    </Fragment>
                                )}
                            </Nav>
                        </Navbar.Collapse>
                    </Stack>
                </Container>
            </Navbar>
            {selectedNavigationLevel && selectedNavigationLevel.navigationGroups.length > 0 &&
                <div className={`bg-body p-3 position-absolute w-100 z-3 ${selectedNavigationLevel ? "d-block" : "d-none"}`}
                     onMouseLeave={() => setSelectedNavigationLevel(null)}
                     style={{boxShadow: "0 0.5em 1em rgba(0,0,0,0.3)"}}
                >
                    <Container fluid>
                        <Row>
                            {selectedNavigationLevel?.navigationGroups?.map((navigationGroup, index) =>
                                <Col key={index} style={{fontSize: "0.875em"}} xs={12} md={4} lg={2} xl={2} xxl={2}>
                                    <div className={"text-primary fw-bold"}>{navigationGroup.label}</div>
                                    {navigationGroup.navigationItems.map((navigationItem, idx) =>
                                        <div key={idx}>
                                            <a className={"text-body text-decoration-none"} href={navigationItem.path}>{navigationItem.label}</a>
                                        </div>
                                    )}
                                </Col>
                            )}
                        </Row>
                    </Container>
                </div>
            }
        </Fragment>
    )
}

export default Navigation
