import {Table} from "react-bootstrap"
import PurchaseOrderFormLine from "../../type/PurchaseOrderFormLine"
import {appendClassName} from "../../utilities/ViewUtility"
import {Fragment} from "react"

export interface LineItemListProps {
    className?: string
    lineItems: PurchaseOrderFormLine[]
    onRemove: (index: number) => void
}

export default function LineItemList(props: LineItemListProps) {

    function getPrice(line: PurchaseOrderFormLine) {
        return line.parts.reduce((previousValue: number, part) => {return previousValue + part.price}, 0.0)
    }

    function getUnitsLabel(units: string[]) {
        return units.reduce((previousValue: string[], unit) => {
            if (unit.length > 0) {
                previousValue.push(unit)
            }
            return previousValue
        }, []).join(", ")
    }

    return (
        <Table className={appendClassName("", props.className)}>
            <thead>
                <tr>
                    <th>Line</th>
                    <th>Number</th>
                    <th>Name</th>
                    <th>Options</th>
                    <th>Imprint</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Extended Price</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {props.lineItems.map((line, index) =>
                    <Fragment key={index}>
                        <tr className={line.charges.length > 0 ? "border-white" : ""}>
                            <td>{index + 1}</td>
                            <td>{line.product.number}</td>
                            <td>{line.product.name}</td>
                            <td>
                                {Object.keys(line.partGroups).map(key =>
                                    <Table borderless className={"mb-0 w-100"} key={key} size={"sm"}>
                                        <tbody>
                                            {line.partGroups[key].dimensions.map(dimension =>
                                                <tr key={dimension.value}>
                                                    <td className={"w-50"}>
                                                        <strong>{dimension.name}:</strong>
                                                    </td>
                                                    <td className={"w-50"}>{dimension.label}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                )}
                            </td>
                            <td>
                                {Object.keys(line.locations).map(key =>
                                    <Table borderless className={"mb-0 w-100"} key={key} size={"sm"}>
                                        <tbody>
                                            <tr>
                                                <th colSpan={2}>{line.locations[key].location.name}</th>
                                            </tr>
                                            {Object.keys(line.locations[key].decorations).map(k =>
                                                <tr key={k}>
                                                    <td className={"w-50"}>{line.locations[key].decorations[k].decoration.name}</td>
                                                    <td className={"w-50"}>{getUnitsLabel(line.locations[key].decorations[k].units)}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                )}
                            </td>
                            <td>{line.quantity}</td>
                            <td>${Number(getPrice(line)).toFixed(2)}</td>
                            <td>${Number(getPrice(line) * line.quantity).toFixed(2)}</td>
                            <td><i className={"bi bi-x-lg"} onClick={() => props.onRemove(index)} style={{cursor: "pointer"}} /></td>
                        </tr>
                        {line.charges.length > 0 &&
                            <tr>
                                <td></td>
                                <td colSpan={6}>
                                    <div className={"fs-5 text-muted border-bottom"}><strong>Charges</strong></div>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Quantity</th>
                                                <th>Unit Price</th>
                                                <th>Extended Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {line.charges.map((charge, index) =>
                                                <tr key={index}>
                                                    <td>{charge.charge.name}</td>
                                                    <td>{charge.charge.type}</td>
                                                    <td>{charge.quantity}</td>
                                                    <td>${Number(charge.unitPrice).toFixed(2)}</td>
                                                    <td>${Number(charge.extendedPrice).toFixed(2)}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </td>
                            </tr>
                        }
                    </Fragment>
                )}
            </tbody>
        </Table>
    )
}
