import PurchaseOrderForm from "../../type/PurchaseOrderForm"
import PurchaseOrderFormLine from "../../type/PurchaseOrderFormLine"
import {useEffect, useState} from "react"
import PurchaseOrderRequest from "../../type/PurchaseOrderRequest"
import ContactDetails from "../../type/ContactDetails"
import Location from "../../type/Location"
import LayerOrStop from "../../type/LayerOrStop"
import {Alert, Button, Form, Spinner, Stack} from "react-bootstrap"
import PurchaseOrderResponse from "../../type/PurchaseOrderResponse"

export interface SubmissionFormProps {
    values: PurchaseOrderForm
    lineItems: PurchaseOrderFormLine[]
    onComplete: () => void
    onClose: () => void
}

export default function SubmissionForm(props: SubmissionFormProps) {

    const [error, setError] = useState<string|null>(null)
    const [request, setRequest] = useState<PurchaseOrderRequest|null>(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [response, setResponse] = useState<PurchaseOrderResponse|null>(null)

    useEffect(() => {
        const now = new Date()
        const contactDetails: ContactDetails = {
            attentionTo: props.values.attentionTo,
            companyName: props.values.companyName,
            address1: props.values.addressLine1,
            address2: props.values.addressLine2,
            address3: props.values.addressLine3,
            city: props.values.city,
            region: props.values.state,
            postalCode: props.values.postalCode,
            country: "USA",
            email: "", // TODO: get this value
            phone: null,
            comments: null
        }
        const purchaseOrderRequest: PurchaseOrderRequest = {
            wsVersion: "1.0.0",
            id: "",
            password: "",
            po: {
                orderType: "Configured",
                orderNumber: props.values.orderNumber,
                orderDate: now.toISOString(),
                lastModified: null,
                totalAmount: 0.0, // TODO: calculate this value
                paymentTerms: null,
                rush: false,
                currency: "USD",
                digitalProof: {
                    required: props.values.proofRequired === "true",
                    digitalProofAddressesArray: [
                        {
                            type: "Email",
                            email: props.values.proofEmail,
                            lineItemGroupingId: 1
                        }
                    ]
                },
                orderContactArray: [
                    {
                        contactType: "Ship",
                        contactDetails: contactDetails,
                        accountName: null,
                        accountNumber: null
                    }
                ],
                shipmentArray: [
                    {
                        customerPickup: false,
                        shipTo: {
                            shipmentId: 1,
                            contactDetails: contactDetails
                        },
                        thirdPartyAccount: {
                            accountName: "",
                            accountNumber: props.values.shippingAccountNumber,
                            contactDetails: contactDetails
                        },
                        shipReferences: [],
                        packingListRequired: false,
                        blindShip: false,
                        allowConsolidation: true,
                        freightDetails: {carrier: props.values.shippingMethod, service: props.values.shippingMethod},
                        comments: null
                    }
                ],
                lineItemArray: props.lineItems.map((lineItem, index) => {
                    const unitPrice = lineItem.parts.reduce((previousValue, part) => {
                        return previousValue + part.price
                    }, 0.0)

                    const locations: Location[] = []
                    return {
                        lineNumber: Number(index + 1).toString(),
                        description: "",
                        lineType: "New",
                        quantity: {
                            value: lineItem.quantity,
                            uom: "EA"
                        },
                        fobId: null,
                        toleranceDetails: {
                            tolerance: "ExactOnly",
                            value: null,
                            uom: null
                        },
                        allowPartialShipments: false,
                        unitPrice: unitPrice,
                        lineItemTotal: unitPrice * lineItem.quantity,
                        requestedShipDate: props.values.shipDate,
                        requestedInHands: props.values.inHandsDate,
                        referenceSalesQuote: null,
                        program: null,
                        endCustomerSalesOrder: null,
                        productId: lineItem.product.number,
                        customerProductId: null,
                        lineItemGroupingId: null,
                        partArray: lineItem.parts.map(part => {
                            return {
                                partGroup: Number(part.group.number).toString(),
                                partId: part.sku,
                                customerPartId: null,
                                customerSupplied: false,
                                description: null,
                                quantity: {
                                    value: lineItem.quantity,
                                    uom: "EA"
                                },
                                locationLinkId: null,
                                unitPrice: part.price,
                                extendedPrice: part.price * lineItem.quantity,
                                shipmentLinkArray: null
                            }
                        }),
                        configuration: {
                            referenceNumber: null,
                            referenceNumberType: null,
                            preproductionProof: false,
                            chargeArray: lineItem.charges.map(charge => {
                                return {
                                    chargeId: Number(charge.charge.chargeId).toString(),
                                    chargeName: charge.charge.name,
                                    description: charge.charge.description,
                                    chargeType: charge.charge.type,
                                    quantity: {
                                        value: charge.quantity,
                                        uom: "EA"
                                    },
                                    unitPrice: charge.unitPrice,
                                    extendedPrice: charge.extendedPrice
                                }
                            }),
                            locationArray: Object.keys(lineItem.locations).map(key => {
                                const location = lineItem.locations[key]
                                return {
                                    locationLinkId: location.location.id,
                                    locationId: location.location.locationId,
                                    locationName: location.location.name,
                                    decorationArray: Object.keys(location.decorations).map(key => {
                                        const decoration = location.decorations[key]
                                        return {
                                            decorationId: decoration.decoration.decorationId,
                                            decorationName: decoration.decoration.name,
                                            artwork: {
                                                refArtworkId: null,
                                                description: null,
                                                dimensions: null,
                                                artworkFileArray: [
                                                    {
                                                        fileName: decoration.filename,
                                                        fileLocation: decoration.path,
                                                        transportMechanism: "Url",
                                                        artworkType: "ProductionReady",
                                                    }
                                                ],
                                                instructions: null,
                                                layers: {
                                                    colorSystem: "Other",
                                                    layerOrStopArray: decoration.units.reduce((previousValue: LayerOrStop[], unit) => {
                                                        if (unit.length > 0) {
                                                            const layer: LayerOrStop = {
                                                                nameOrNumber: unit,
                                                                description: unit,
                                                                color: unit
                                                            }
                                                            previousValue.push(layer)
                                                        }
                                                        return previousValue
                                                    }, [])
                                                },
                                                typesetArray: null,
                                                totalStitchCount: null
                                            }
                                        }
                                    })
                                }
                            })
                        }
                    }
                }),
                termsAndConditions: "",
                salesChannel: null,
                promoCode: null,
                taxInformationArray: []
            }
        }
        setRequest(purchaseOrderRequest)
    }, [props.values, props.lineItems])

    function handleSubmit() {
        setError(null)
        setIsSubmitting(true)
        setResponse(null)
        if (!request) {
            return false
        }
        let formData = new FormData()
        let index = 0
        formData.append('wsVersion', request.wsVersion)
        formData.append("id", request.id)
        formData.append("password", request.password)
        formData.append("po[orderType]", request.po.orderType)
        formData.append("po[orderNumber]", request.po.orderNumber)
        formData.append("po[orderDate]", request.po.orderDate)
        formData.append("po[totalAmount]", Number(request.po.totalAmount).toString())
        formData.append("po[rush]", request.po.rush ? "true" : "false")
        formData.append("po[currency]", request.po.currency)
        formData.append("po[digitalProof][required]", request.po.digitalProof?.required ? "true" : "false")
        formData.append("po[digitalProof][digitalProofAddressesArray][0][type]", request.po.digitalProof?.digitalProofAddressesArray[0].type ?? "")
        formData.append("po[digitalProof][digitalProofAddressesArray][0][email]", request.po.digitalProof?.digitalProofAddressesArray[0].email ?? "")
        formData.append("po[digitalProof][digitalProofAddressesArray][0][lineItemGroupingId]", Number(request.po.digitalProof?.digitalProofAddressesArray[0].lineItemGroupingId).toString() ?? "")
        for (const orderContact of request.po.orderContactArray) {
            formData.append(`po[orderContactArray][${index}][contactType]`, orderContact.contactType)
            formData.append(`po[orderContactArray][${index}][contactDetails][attentionTo]`, orderContact.contactDetails.attentionTo ?? "")
            formData.append(`po[orderContactArray][${index}][contactDetails][companyName]`, orderContact.contactDetails.companyName ?? "")
            formData.append(`po[orderContactArray][${index}][contactDetails][address1]`, orderContact.contactDetails.address1 ?? "")
            formData.append(`po[orderContactArray][${index}][contactDetails][address2]`, orderContact.contactDetails.address2 ?? "")
            formData.append(`po[orderContactArray][${index}][contactDetails][address3]`, orderContact.contactDetails.address3 ?? "")
            formData.append(`po[orderContactArray][${index}][contactDetails][city]`, orderContact.contactDetails.city ?? "")
            formData.append(`po[orderContactArray][${index}][contactDetails][region]`, orderContact.contactDetails.region ?? "")
            formData.append(`po[orderContactArray][${index}][contactDetails][postalCode]`, orderContact.contactDetails.postalCode ?? "")
            formData.append(`po[orderContactArray][${index}][contactDetails][country]`, orderContact.contactDetails.country ?? "")
            formData.append(`po[orderContactArray][${index}][contactDetails][email]`, orderContact.contactDetails.email ?? "")
            formData.append(`po[orderContactArray][${index}][contactDetails][phone]`, orderContact.contactDetails.phone ?? "")
            formData.append(`po[orderContactArray][${index}][contactDetails][comments]`, orderContact.contactDetails.comments ?? "")
            formData.append(`po[orderContactArray][${index}][accountName]`, orderContact.accountName ?? "")
            formData.append(`po[orderContactArray][${index}][accountNumber]`, orderContact.accountNumber ?? "")
            index++
        }
        index = 0
        for (const shipment of request.po.shipmentArray) {
            formData.append(`po[shipmentArray][${index}][customerPickup]`, shipment.customerPickup ? "true" : "false")
            formData.append(`po[shipmentArray][${index}][shipTo][shipmentId]`, Number(shipment.shipTo.shipmentId).toString())
            formData.append(`po[shipmentArray][${index}][shipTo][contactDetails][attentionTo]`, shipment.shipTo.contactDetails.attentionTo ?? "")
            formData.append(`po[shipmentArray][${index}][shipTo][contactDetails][companyName]`, shipment.shipTo.contactDetails.companyName ?? "")
            formData.append(`po[shipmentArray][${index}][shipTo][contactDetails][address1]`, shipment.shipTo.contactDetails.address1 ?? "")
            formData.append(`po[shipmentArray][${index}][shipTo][contactDetails][address2]`, shipment.shipTo.contactDetails.address2 ?? "")
            formData.append(`po[shipmentArray][${index}][shipTo][contactDetails][address3]`, shipment.shipTo.contactDetails.address3 ?? "")
            formData.append(`po[shipmentArray][${index}][shipTo][contactDetails][city]`, shipment.shipTo.contactDetails.city ?? "")
            formData.append(`po[shipmentArray][${index}][shipTo][contactDetails][region]`, shipment.shipTo.contactDetails.region ?? "")
            formData.append(`po[shipmentArray][${index}][shipTo][contactDetails][postalCode]`, shipment.shipTo.contactDetails.postalCode ?? "")
            formData.append(`po[shipmentArray][${index}][shipTo][contactDetails][country]`, shipment.shipTo.contactDetails.country ?? "")
            formData.append(`po[shipmentArray][${index}][shipTo][contactDetails][email]`, shipment.shipTo.contactDetails.email ?? "")
            formData.append(`po[shipmentArray][${index}][shipTo][contactDetails][phone]`, shipment.shipTo.contactDetails.phone ?? "")
            formData.append(`po[shipmentArray][${index}][shipTo][contactDetails][comments]`, shipment.shipTo.contactDetails.comments ?? "")
            if (shipment.thirdPartyAccount) {
                formData.append(`po[shipmentArray][${index}][thirdPartyAccount][accountName]`, shipment.thirdPartyAccount.accountName)
                formData.append(`po[shipmentArray][${index}][thirdPartyAccount][accountNumber]`, shipment.thirdPartyAccount.accountNumber)
                formData.append(`po[shipmentArray][${index}][thirdPartyAccount][contactDetails][attentionTo]`, shipment.thirdPartyAccount.contactDetails.attentionTo ?? "")
                formData.append(`po[shipmentArray][${index}][thirdPartyAccount][contactDetails][companyName]`, shipment.thirdPartyAccount.contactDetails.companyName ?? "")
                formData.append(`po[shipmentArray][${index}][thirdPartyAccount][contactDetails][address1]`, shipment.thirdPartyAccount.contactDetails.address1 ?? "")
                formData.append(`po[shipmentArray][${index}][thirdPartyAccount][contactDetails][address2]`, shipment.thirdPartyAccount.contactDetails.address2 ?? "")
                formData.append(`po[shipmentArray][${index}][thirdPartyAccount][contactDetails][address3]`, shipment.thirdPartyAccount.contactDetails.address3 ?? "")
                formData.append(`po[shipmentArray][${index}][thirdPartyAccount][contactDetails][city]`, shipment.thirdPartyAccount.contactDetails.city ?? "")
                formData.append(`po[shipmentArray][${index}][thirdPartyAccount][contactDetails][region]`, shipment.thirdPartyAccount.contactDetails.region ?? "")
                formData.append(`po[shipmentArray][${index}][thirdPartyAccount][contactDetails][postalCode]`, shipment.thirdPartyAccount.contactDetails.postalCode ?? "")
                formData.append(`po[shipmentArray][${index}][thirdPartyAccount][contactDetails][country]`, shipment.thirdPartyAccount.contactDetails.country ?? "")
                formData.append(`po[shipmentArray][${index}][thirdPartyAccount][contactDetails][email]`, shipment.thirdPartyAccount.contactDetails.email ?? "")
                formData.append(`po[shipmentArray][${index}][thirdPartyAccount][contactDetails][phone]`, shipment.thirdPartyAccount.contactDetails.phone ?? "")
                formData.append(`po[shipmentArray][${index}][thirdPartyAccount][contactDetails][comments]`, shipment.thirdPartyAccount.contactDetails.comments ?? "")
            }
            formData.append(`po[shipmentArray][${index}][packingListRequired]`, shipment.packingListRequired ? "true" : "false")
            formData.append(`po[shipmentArray][${index}][blindShip]`, shipment.blindShip ? "true" : "false")
            formData.append(`po[shipmentArray][${index}][allowConsolidation]`, shipment.allowConsolidation ? "true" : "false")
            formData.append(`po[shipmentArray][${index}][freightDetails][carrier]`, shipment.freightDetails.carrier)
            formData.append(`po[shipmentArray][${index}][freightDetails][service]`, shipment.freightDetails.service)
            formData.append(`po[shipmentArray][${index}][comments]`, shipment.comments ?? "")
            index++
        }
        index = 0
        for (const lineItem of request.po.lineItemArray) {
            formData.append(`po[lineItemArray][${index}][lineNumber]`, lineItem.lineNumber)
            formData.append(`po[lineItemArray][${index}][description]`, lineItem.description)
            formData.append(`po[lineItemArray][${index}][lineType]`, lineItem.lineType)
            if (lineItem.quantity) {
                formData.append(`po[lineItemArray][${index}][quantity][value]`, Number(lineItem.quantity.value).toString())
                formData.append(`po[lineItemArray][${index}][quantity][uom]`, lineItem.quantity.uom)
            }
            formData.append(`po[lineItemArray][${index}][toleranceDetails][tolerance]`, lineItem.toleranceDetails.tolerance)
            formData.append(`po[lineItemArray][${index}][toleranceDetails][value]`, Number(lineItem.toleranceDetails.value).toString())
            formData.append(`po[lineItemArray][${index}][toleranceDetails][uom]`, lineItem.toleranceDetails.uom ?? "")
            formData.append(`po[lineItemArray][${index}][allowPartialShipments]`, lineItem.allowPartialShipments ? "true" : "false")
            formData.append(`po[lineItemArray][${index}][unitPrice]`, Number(lineItem.unitPrice).toString())
            formData.append(`po[lineItemArray][${index}][lineItemTotal]`, Number(lineItem.lineItemTotal).toString())
            formData.append(`po[lineItemArray][${index}][requestedShipDate]`, lineItem.requestedShipDate ?? "")
            formData.append(`po[lineItemArray][${index}][requestedInHands]`, lineItem.requestedInHands ?? "")
            formData.append(`po[lineItemArray][${index}][productId]`, lineItem.productId ?? "")
            let i = 0
            for (const part of lineItem.partArray) {
                formData.append(`po[lineItemArray][${index}][partArray][${i}][partGroup]`, Number(part.partGroup).toString())
                formData.append(`po[lineItemArray][${index}][partArray][${i}][partId]`, part.partId)
                formData.append(`po[lineItemArray][${index}][partArray][${i}][customerSupplied]`, part.customerSupplied ? "true" : "false")
                formData.append(`po[lineItemArray][${index}][partArray][${i}][description]`, part.description ?? "")
                formData.append(`po[lineItemArray][${index}][partArray][${i}][quantity][value]`, Number(part.quantity.value).toString())
                formData.append(`po[lineItemArray][${index}][partArray][${i}][quantity][uom]`, part.quantity.uom)
                formData.append(`po[lineItemArray][${index}][partArray][${i}][unitPrice]`, Number(part.unitPrice).toString())
                formData.append(`po[lineItemArray][${index}][partArray][${i}][extendedPrice]`, Number(part.extendedPrice).toString())
                i++
            }
            formData.append(`po[lineItemArray][${index}][configuration][preProductionProof]`, lineItem.configuration.preproductionProof ? "true" : "false")
            i = 0
            for (const charge of lineItem.configuration.chargeArray) {
                formData.append(`po[lineItemArray][${index}][configuration][chargeArray][${i}][chargeId]`, charge.chargeId)
                formData.append(`po[lineItemArray][${index}][configuration][chargeArray][${i}][chargeName]`, charge.chargeName ?? "")
                formData.append(`po[lineItemArray][${index}][configuration][chargeArray][${i}][description]`, charge.description ?? "")
                formData.append(`po[lineItemArray][${index}][configuration][chargeArray][${i}][chargeType]`, charge.chargeType)
                formData.append(`po[lineItemArray][${index}][configuration][chargeArray][${i}][quantity][value]`, Number(charge.quantity.value).toString())
                formData.append(`po[lineItemArray][${index}][configuration][chargeArray][${i}][quantity][uom]`, charge.quantity.uom)
                formData.append(`po[lineItemArray][${index}][configuration][chargeArray][${i}][unitPrice]`, Number(charge.unitPrice).toString())
                formData.append(`po[lineItemArray][${index}][configuration][chargeArray][${i}][extendedPrice]`, Number(charge.unitPrice).toString())
                i++
            }
            i = 0
            for (const location of lineItem.configuration.locationArray) {
                formData.append(`po[lineItemArray][${index}][configuration][locationArray][${i}][locationLinkId]`, Number(location.locationLinkId).toString())
                formData.append(`po[lineItemArray][${index}][configuration][locationArray][${i}][locationId]`, Number(location.locationId).toString())
                formData.append(`po[lineItemArray][${index}][configuration][locationArray][${i}][locationName]`, location.locationName ?? "")
                let j = 0
                for (const decoration of location.decorationArray) {
                    formData.append(`po[lineItemArray][${index}][configuration][locationArray][${i}][decorationArray][${j}][decorationId]`, Number(decoration.decorationId).toString())
                    formData.append(`po[lineItemArray][${index}][configuration][locationArray][${i}][decorationArray][${j}][decorationName]`, decoration.decorationName ?? "")
                    let k = 0
                    for (const artworkFile of decoration.artwork.artworkFileArray) {
                        formData.append(`po[lineItemArray][${index}][configuration][locationArray][${i}][decorationArray][${j}][artwork][artworkFileArray][${k}][fileName]`, artworkFile.fileName)
                        formData.append(`po[lineItemArray][${index}][configuration][locationArray][${i}][decorationArray][${j}][artwork][artworkFileArray][${k}][fileLocation]`, artworkFile.fileLocation)
                        formData.append(`po[lineItemArray][${index}][configuration][locationArray][${i}][decorationArray][${j}][artwork][artworkFileArray][${k}][transportMechanism]`, artworkFile.transportMechanism)
                        formData.append(`po[lineItemArray][${index}][configuration][locationArray][${i}][decorationArray][${j}][artwork][artworkFileArray][${k}][artworkType]`, artworkFile.artworkType)
                        k++
                    }
                    formData.append(`po[lineItemArray][${index}][configuration][locationArray][${i}][decorationArray][${j}][artwork][instructions]`, decoration.artwork.instructions ?? "")
                    if (decoration.artwork.layers) {
                        formData.append(`po[lineItemArray][${index}][configuration][locationArray][${i}][decorationArray][${j}][artwork][layers][colorSystem]`, decoration.artwork.layers.colorSystem)
                        k = 0
                        for (const layerOrStop of decoration.artwork.layers.layerOrStopArray) {
                            formData.append(`po[lineItemArray][${index}][configuration][locationArray][${i}][decorationArray][${j}][artwork][layers][layerOrStopArray][${k}][nameOrNumber]`, layerOrStop.nameOrNumber)
                            formData.append(`po[lineItemArray][${index}][configuration][locationArray][${i}][decorationArray][${j}][artwork][layers][layerOrStopArray][${k}][description]`, layerOrStop.description)
                            formData.append(`po[lineItemArray][${index}][configuration][locationArray][${i}][decorationArray][${j}][artwork][layers][layerOrStopArray][${k}][color]`, layerOrStop.color)
                            k++
                        }
                    }
                    j++
                }
            }
            index++
        }
        formData.append("po[termsAndConditions]", request.po.termsAndConditions)
        formData.append("po[salesChannel]", request.po.salesChannel ?? "")
        formData.append("po[promoCode]", request.po.promoCode ?? "")

        fetch(`${process.env.REACT_APP_BASE_URL}/poSubmission/poRequest`,
        {
            body: formData,
            method: "post"
        })
            .then(data => data.json())
            .then(data => {
                setResponse(data)
                if (data.transactionIdArray.length > 0) {
                    props.onComplete()
                }
            })
            .catch(error => {
                setError("Failed to submit order.")
                console.error(error)
            })
            .finally(() => setIsSubmitting(false))
    }

    return (
        <Form encType={"multipart/form-data"} method={"post"} onSubmit={handleSubmit}>
            <Stack direction={"vertical"} gap={3}>
                {error &&
                    <Alert variant={"danger"}>
                        {error}
                    </Alert>
                }
                {response !== null && response.transactionIdArray.length > 0 &&
                    <Alert variant={"success"}>
                        <div className={"h5"}>
                            <i className={"bi bi-check-circle me-2"} />
                            Order Successfully Submitted
                        </div>
                        <strong className={"me-1"}>Order Number{response.transactionIdArray.length > 1 && "s"}:</strong>
                        {response.transactionIdArray.join(", ")}
                    </Alert>
                }
                {response !== null && response.transactionIdArray.length === 0 &&
                    <Alert variant={"danger"}>
                        <i className={"bi bi-exclamation-triangle me-2"} />
                        Order Submission Failed!
                    </Alert>
                }
                {(response === null || response.transactionIdArray.length === 0) &&
                    <Button disabled={isSubmitting} type={"submit"}>
                        Submit{isSubmitting && "ting"} Order
                        {isSubmitting && <Spinner className={"ms-2"} size={"sm"} />}
                    </Button>
                }
                {response !== null && response.transactionIdArray.length > 0 &&
                    <Button onClick={() => props.onClose()}>Finish</Button>
                }
            </Stack>
        </Form>

    )
}