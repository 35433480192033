import PurchaseOrderProduct from "../../type/PurchaseOrderProduct"
import {Fragment, useEffect, useState} from "react"
import PurchaseOrderProductConfigurationData from "../../type/PurchaseOrderProductConfigurationData"
import {Alert, Button, Card, Form, Placeholder, PlaceholderButton} from "react-bootstrap"
import PurchaseOrderPart from "../../type/PurchaseOrderPart"
import PurchaseOrderFormLine from "../../type/PurchaseOrderFormLine"
import PurchaseOrderDimension from "../../type/PurchaseOrderDimension"
import PurchaseOrderProductLocation from "../../type/PurchaseOrderProductLocation"
import LocationList from "./LocationList"
import PurchaseOrderFormLocation from "../../type/PurchaseOrderFormLocation"
import PurchaseOrderFormCharge from "../../type/PurchaseOrderFormCharge"

export interface ProductConfigurationProps {
    onSubmit: (line: PurchaseOrderFormLine) => void
}
function ProductConfiguration(props: ProductConfigurationProps) {

    const [configurationDataError, setConfigurationDataError] = useState<string|null>(null)
    const [productDataError, setProductDataError] = useState<string|null>(null)
    const [dimensions, setDimensions] = useState<PurchaseOrderDimension[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(false)
    const [selection, setSelection] = useState<{[index: string]: any}>({})
    const [configurationData, setConfigurationData] = useState<PurchaseOrderProductConfigurationData|null>(null)
    const [parts, setParts] = useState<{[index: string]: PurchaseOrderPart}>({})
    const [products, setProducts] = useState<PurchaseOrderProduct[]>([])
    const [selectedLocations, setSelectedLocations] = useState<{[index: string]: PurchaseOrderFormLocation}>({})
    const [selectedProduct, setSelectedProduct] = useState<PurchaseOrderProduct | null>(null)
    const [quantity, setQuantity] = useState<string>("1")

    /**
     * Fetch product data
     */
    useEffect(() => {
        setIsLoadingProducts(true)
        setProductDataError(null)
        fetch(`${process.env.REACT_APP_BASE_URL}/poSubmission/products`)
            .then(data => data.json())
            .then(data => setProducts(data))
            .catch(error => {
                setProductDataError("Unable to load product data.")
                console.error(error)
            })
            .finally(() => setIsLoadingProducts(false))
    }, [])

    /**
     * Fetch configuration data
     */
    useEffect(() => {
        setConfigurationData(null)
        setConfigurationDataError(null)
        setSelection({})
        setSelectedLocations({})
        setParts({})
        if (!selectedProduct) { return }
        setIsLoading(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/poSubmission/productConfiguration?productId=${selectedProduct.id}`)
            .then(data => data.json())
            .then(data => setConfigurationData(data))
            .catch(error => setConfigurationDataError("Unable to load configuration data."))
            .finally(() => setIsLoading(false))
    }, [selectedProduct])

    /**
     * Set Options
     */
    useEffect(() => {
        if (!configurationData) { return }
        const newDimensions = configurationData.dimensions.map(dimension => {
            if (configurationData.dictionaries.dimensions[dimension.key]) {
                dimension.options = Object.keys(configurationData.dictionaries.dimensions[dimension.key]).map((key: string) => configurationData.dictionaries.dimensions[dimension.key][key])
            }
            return dimension
        })
        setDimensions(newDimensions)
    }, [configurationData])

    /**
     * Set Parts
     */
    useEffect(() => {
        const parts = configurationData?.products.filter(product => {
            if (configurationData?.dimensions.length === 0 && configurationData.products.length === 1) {
                return true
            }
            return Object.keys(product.dimensions).reduce((previousValue, dimension) => {
                return previousValue && selection[dimension] === product.dimensions[dimension]
            }, Object.keys(product.dimensions).length > 0)
        })
        if (parts !== undefined) {
            const newParts: {[index: string]: PurchaseOrderPart} = parts.reduce((previousValue: {[index: string]: PurchaseOrderPart}, part: PurchaseOrderPart) => {
                previousValue[part.group.number] = part
                return previousValue
            }, {})
            setParts(prevState => {
                return {...prevState, ...newParts}
            })
        }
    }, [selection, configurationData?.dimensions.length, configurationData?.products]);

    /**
     * Set product.
     * @param productId
     */
    function handleProductSelection(productId: string) {
        const product = products.find((product) => product.id === productId)
        setSelectedProduct(product ?? null)
    }

    /**
     * Set selection key/value pair.
     * @param key
     * @param value
     */
    function handleSelection(key: string, value: string) {
        let newSelection: {[index: string]: any} = {}
        newSelection[key] = value
        setSelection(prevState => {
            return {...prevState, ...newSelection}
        })
    }

    /**
     * Set location key/value pair.
     * @param locations
     */
    function handleLocationChange(locations: {[index: string]: PurchaseOrderFormLocation}) {
        setSelectedLocations(locations)
    }

    /**
     * Add line item.
     * @param event
     */
    function handleSubmit(event: React.FormEvent) {
        event.preventDefault()
        if (!selectedProduct) { return }
        const partGroups: PurchaseOrderFormLine["partGroups"] = Object.keys(parts).reduce((previousValue: any, key) => {
            const part = parts[key]
            const dimensions = Object.keys(part.dimensions).map(key => {
                const value = part.dimensions[key]
                return configurationData?.dictionaries.dimensions[key][value]
            })
            previousValue[part.group.number] = {...part.group, ...{dimensions: dimensions}}
            return previousValue
        }, {})
        const charges: PurchaseOrderFormCharge[] = Object.keys(selectedLocations).reduce((previousValue: PurchaseOrderFormCharge[], key: string) => {
            const location = selectedLocations[key]
            const firstLocation = Object.keys(selectedLocations)[0] === key
            for (const key of Object.keys(location.decorations)) {
                const decoration = location.decorations[key]
                for (const charge of decoration.decoration.charges) {
                    const colors = decoration.units.reduce((previousValue, unit) => {
                        return previousValue + (unit.length > 0 ? 1 : 0)
                    }, 0)
                    let newQuantity = charge.type === "Setup" ? 1 : Number(quantity)
                    const unitPrice = charge.prices.reduce((previousValue, price) => {
                        if (["PERSONALIZATION", "INDIVIDUAL PERSONALIZATION"].includes(charge.name)) {
                            return previousValue // Item personalization (initials, name, etc.) is not supported at this time.
                        }
                        if (charge.name === "EXTRA LOCATION CHARGE" && (firstLocation || Object.keys(selectedLocations).length < price.yMinQuantity)) {
                            return previousValue
                        }
                        if (price.yUnitOfMeasure === "Colors" && colors < price.yMinQuantity) {
                            return previousValue
                        }
                        return previousValue + price.price
                    }, 0)
                    const newCharge: PurchaseOrderFormCharge = {
                        charge: charge,
                        quantity: newQuantity,
                        unitPrice: unitPrice,
                        extendedPrice: unitPrice * newQuantity
                    }
                    if (newCharge.unitPrice > 0) {
                        previousValue.push(newCharge)
                    }
                }
            }
            return previousValue
        }, [])
        const line: PurchaseOrderFormLine = {
            charges: charges,
            partGroups: partGroups,
            product: selectedProduct,
            parts: Object.keys(parts).map(key => parts[key]),
            locations: selectedLocations,
            quantity: Number(quantity)
        }
        props.onSubmit(line)
        resetForm()
    }

    /**
     * Reset the product configuration form
     */
    function resetForm() {
        setConfigurationDataError(null)
        setSelection({})
        setSelectedLocations({})
        setConfigurationData(null)
        setParts({})
        setSelectedProduct(null)
        setQuantity("1")
    }

    return (
        <div>
            {isLoadingProducts &&
                <Placeholder animation={"glow"}>
                    <Placeholder className={"rounded"} as={Form.Label} size={"lg"} xs={6} xl={4} />
                    <Placeholder className={"mb-2"} as={Form.Select} xs={12} />
                </Placeholder>
            }
            {productDataError &&
                <Alert variant={"danger"}>
                    <i className={"bi bi-exclamation-triangle me-2"} />
                    {productDataError}
                </Alert>
            }
            {configurationDataError &&
                <Alert variant={"danger"}>
                    <i className={"bi bi-exclamation-triangle me-2"} />
                    {configurationDataError}
                </Alert>
            }
            <Form onSubmit={(event) => handleSubmit(event)}>
                {!isLoadingProducts &&
                    <Form.Group className={"mb-3"}>
                        <Form.Label><strong>Product</strong></Form.Label>
                        <Form.Select disabled={isLoading} onChange={(event) => handleProductSelection(event.target.value)} value={selectedProduct?.id ?? ""}>
                            <option value={""}>Select a Product</option>
                            {products.map((product) =>
                                <option key={product.id} value={product.id}>{`${product.number} - ${product.name}`}</option>
                            )}
                        </Form.Select>
                    </Form.Group>
                }

                {isLoading &&
                    <Placeholder animation={"glow"}>
                        <Placeholder className={"rounded"} as={Form.Label} size={"lg"} xs={6} xl={4} />
                        <Placeholder className={"mb-2"} as={Form.Select} xs={12} />
                        <Placeholder className={"mb-2"} as={Form.Select} xs={12} />
                        <PlaceholderButton xs={4} xl={2} />
                    </Placeholder>
                }

                {!isLoading && configurationData &&
                    <Fragment>
                        {dimensions.map(dimension =>
                            <Form.Group className={"mb-3"} key={dimension.key}>
                                <Form.Label><strong>{dimension.name}</strong></Form.Label>
                                <Form.Select onChange={(event) => handleSelection(dimension.key, event.target.value)}>
                                    <option value={""}>Select a {dimension.name}</option>
                                    {dimension.options?.map(option =>
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    )}
                                </Form.Select>
                            </Form.Group>
                        )}
                        <Form.Group className={"mb-3"}>
                            <Form.Label><strong>Quantity</strong></Form.Label>
                            <Form.Control type={"number"} min={1} onChange={(event) => setQuantity(event.target.value)} required value={quantity} />
                        </Form.Group>

                        {configurationData.locations.length > 0 &&
                            <Fragment>
                                <p className={"h5"}><strong>Locations</strong></p>
                                <LocationList locations={configurationData.locations} onChange={handleLocationChange} selectedLocations={selectedLocations} />
                            </Fragment>
                        }

                        <Button disabled={Object.keys(parts).length !== Object.keys(configurationData.partGroups).length} type={"submit"}>Add Product</Button>
                    </Fragment>
                }
            </Form>
        </div>
    )
}

export default ProductConfiguration
