import React from 'react'
import {Outlet} from "react-router-dom"
import Navigation from "./components/navigation/Navigation"

function App() {
    return (
        <div className="App">
            <header>
                <Navigation />
            </header>
            <Outlet/>
        </div>
    )
}

export default App;
